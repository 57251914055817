/* Ensure these styles are included in your stylesheet */

.mob {
    display: block;
  }
  
  @media (max-width: 600px) {
    .welcome-text {
      display: block;
    }
  
    .name-text {
      display: block;
      margin-top: 0.5rem; /* Adjust as needed for spacing */
    }
  
    .mob1 {
      display: none;
    }
  }
  